import React, { useRef, useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Tilt from "react-tilt";
import { useSpring, animated } from 'react-spring';
import { flatten, uniq, intersection } from "lodash-es";

import Layout from "../components/layout/Layout";
import { LogoCloud } from "../components/LogoCloud";
import { BlogPosts } from "../templates/index-page-template";
import Transcript from "../components/Transcript";
import SalesQuestion from "../components/SalesQuestion";
import { slug } from "../helpers";
import { useWindowResize } from "../templates/index-page-template";
import { ContactPersonsSection } from "./oneoffs/de-custom-pages-template";

import small_transcript_orange from "../assets/images/small_transcript_orange.svg";
import orange_chevron from "../assets/images/orange_chevron.svg";

export default ({ data, location, pageContext }) => {
    const mobileView = useWindowResize();
    const menuItems = data.allContentfulPartnerPage.edges;
    const {
        title,
        slug: curPageSlug,
        heroSection,
        contentSections,
        bottomSection
    } = data.contentfulPartnerPage;

    const articles = data.allContentfulArticle.edges;

    return (
        <Layout
            title={title}
            pageContentId="partner-page"
            location={location}
            transparentNavigation
            language={pageContext.locale}
        >
            <main id="partner-page" style={{ marginTop: "-80px" }}>
                <HeroSection
                    coverImage={heroSection.coverImage}
                    titlePart1={heroSection.titlePart1}
                    titlePart2={heroSection.titlePart2}
                    description={heroSection.description}
                    locale={pageContext.locale}
                />

                {mobileView ? (
                    <>
                        {contentSections.map((s, i) => (
                            <MobileContentSection
                                key={i}
                                section={s}
                                title={i === 0 ? title : undefined}
                            />
                        ))}
                    </>
                ) : (
                    <DesktopMenuAndContentSections
                        curPageSlug={curPageSlug}
                        menuItems={menuItems}
                        title={title}
                        contentSections={contentSections}
                        locale={pageContext.locale}
                    />
                )}

                <LogoCloud
                    titlePart1={bottomSection.logoCloudTitlePart1}
                    titlePart2={bottomSection.logoCloudTitlePart2}
                    logos={bottomSection.logoCloud.logo}
                />

                <BlogPosts
                    blogPosts={articles}
                    colors={['green']}
                    isForPartnerships
                    locale={pageContext.locale}
                />

                <Transcript
                    iconColor="green"
                    textColor="black"
                    link={bottomSection.ctaLink}
                    title={bottomSection.ctaTitle}
                    subtitle={bottomSection.ctaSubtitle}
                    outerLink={pageContext.locale === 'de'}
                />

                {pageContext.locale === 'de'
                    ? (
                        <ContactPersonsSection contactPersons={['Robert Panholzer']} />
                    ) : (
                        <SalesQuestion
                            isGrey={true}
                            color="green"
                            email="juha@frends.com"
                            name="Juha Moisio"
                            tel="+358 40 739 1412"
                            title="Global Partners"
                            locale={pageContext.locale}
                        />
                    )}

                {mobileView && (
                    <MobileMenu
                        curPageSlug={curPageSlug}
                        menuItems={menuItems}
                        locale={pageContext.locale}
                    />
                )}
            </main>
        </Layout>
    );
}

const HeroSection = ({
    coverImage,
    titlePart1,
    titlePart2,
    description,
    locale,
}) => {
    const isLocaleDe = () => locale === 'de';

    const getTranslation = (key) => {
        if (key === 'joinNow') {
            return isLocaleDe() ? 'Jetzt beitreten' : 'Join now';
        }
        if (key === 'logIn') {
            return isLocaleDe() ? 'Anmeldung' : 'Log in';
        }
        return key;
    };

    return (
        <section className="hero is-fullheight">
            <GatsbyImage
                image={getImage(coverImage)}
                alt=""
                style={{
                    width: "100%",
                    height: "100dvh",
                    position: "absolute",
                    top: "0",
                    left: "0"
                }}
            />
            <div className="hero-body">
                <div
                    className="container has-text-centered"
                    style={{ maxWidth: '800px' }}
                >
                    <h1 className="title margin-bottom-0">
                        <span className="is-white font-variable"
                            style={{ lineHeight: '1' }}
                        >
                            {titlePart1}
                        </span>
                        &nbsp;
                        <span className="is-white"
                            style={{ lineHeight: '1' }}
                        >
                            {titlePart2}
                        </span>
                    </h1>

                    <div className="is-flex is-justify-content-center margin-top-40 is-half is-offset-one-quarter">
                        <p className="is-white font-regular margin-bottom-10"
                            style={{
                                maxWidth: '550px',
                                lineHeight: '1.75'
                            }}
                        >
                            {description}
                        </p>
                    </div>

                    <div className="is-flex is-justify-content-center margin-top-50 partner-cta-links">
                        <Link to="/apply-for-partnership">
                            {getTranslation('joinNow')}
                        </Link>

                        <a href="https://partners.frends.com/" target="_blank">
                            {getTranslation('logIn')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

const DesktopMenuAndContentSections = ({
    curPageSlug,
    menuItems,
    title,
    contentSections,
    locale,
}) => {
    const contentContainerRef = useRef(null);
    const menuContainerRef = useRef(null);
    const [menuTop, setMenuTop] = useState(0);
    const [menuLeft, setMenuLeft] = useState(0);
    const [menuPosition, setMenuPosition] = useState('absolute');

    useEffect(() => {
        const handleWindowScroll = () => {
            if (!contentContainerRef.current || !menuContainerRef.current) {
                return;
            }

            const topPosition = window.scrollY - window.innerHeight;

            if (topPosition < 0) {
                setMenuPosition('absolute');
                return;
            }

            const maxScroll = window.innerHeight + contentContainerRef.current.offsetHeight - menuContainerRef.current.offsetHeight;
            if (window.scrollY < maxScroll) {
                setMenuTop(0);
                setMenuPosition('fixed');
                return;
            }

            if (menuContainerRef.current.style.position !== 'absolute') {
                setMenuTop(topPosition)
                setMenuPosition('absolute');
            }
        };

        handleWindowScroll();

        window.addEventListener('scroll', handleWindowScroll);

        return () => window.removeEventListener('scroll', handleWindowScroll);
    }, []);

    const handleContentPositionChange = (leftHandContStartX) => {
        setMenuLeft(leftHandContStartX);
    };

    const menuPositionStyle = useMemo(() => {
        return {
            top: `${menuTop}px`,
            left: `${menuLeft}px`,
            position: menuPosition,
            display: menuLeft === 0 ? 'none' : 'block',
        };
    }, [menuTop, menuLeft, menuPosition]);

    return (
        <div
            ref={contentContainerRef}
            className="relative-content-container is-hidden-touch"
        >
            <div
                ref={menuContainerRef}
                className="container absolute-menu-container"
                style={menuPositionStyle}
            >
                <Menu
                    curPageSlug={curPageSlug}
                    menuItems={menuItems}
                    locale={locale}
                />
            </div>

            {contentSections.map((s, i) => (
                <DesktopContentSection
                    key={i}
                    section={s}
                    title={i === 0 ? title : undefined}
                    onContentPositionChange={handleContentPositionChange}
                />
            ))}
        </div>
    )
}

const DesktopContentSection = ({
    title,
    section,
    onContentPositionChange,
}) => {
    const leftHandContainerRef = useRef(null);

    useEffect(() => {
        const handleWindowResize = () => {
            if (!leftHandContainerRef.current) {
                return;
            }

            const leftHandContRect = leftHandContainerRef.current.getBoundingClientRect();
            const leftHandContStartX = leftHandContRect.left + window.scrollX
            onContentPositionChange(leftHandContStartX);
        };

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <section className={`section is-hidden-touch partner-section ${section.backgroundColor === '#FFF6F0' ? 'orange-bg' : 'white-bg'}`}>
            <div className="container">
                <div className="columns is-multiline">
                    <div
                        ref={leftHandContainerRef}
                        className="column is-4"
                    />

                    <div className="column is-8">
                        <Content
                            title={title}
                            section={section}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const MobileContentSection = ({
    title,
    section,
}) => (
    <section className={`section is-hidden-tablet partner-section ${section.backgroundColor === '#FFF6F0' ? 'orange-bg' : 'white-bg'}`}>
        <div className="container">
            <Content
                title={title}
                section={section}
            />
        </div>
    </section>
);

const Content = ({ title, section, }) => (
    <>
        {title && (
            <h1
                id={`${slug(title)}`}
                className="size-50 is-black margin-bottom-38"
            >
                {title}
            </h1>
        )}

        {section.title && (
            <h2
                id={`${slug(section.title)}`}
                className="size-30 is-orange margin-bottom-34"
            >
                {section.title}
            </h2>
        )}

        {section.childContentfulPartnerPageSectionContentTextNode ? (
            <div
                className="article-content content partner-page-content"
                dangerouslySetInnerHTML={{ __html: section.childContentfulPartnerPageSectionContentTextNode.childMarkdownRemark.html }}
            />
        ) : (
            <CoSellPartners
                coSellPartners={section.coSellPartners}
                showCountryFilters={section.showCountryFilters}
                showTypeFilters={section.showTypeFilters}
            />
        )}
    </>
)

const Menu = ({ menuItems, curPageSlug, onItemClick, locale, }) => {
    const [openItem, setOpenItem] = useState(curPageSlug);

    const handleOpenItemChange = (item) => setOpenItem(item);

    const getMenuItems = () => {
        if (locale === 'de') {
            return menuItems.slice(0, menuItems.length - 1);
        }
        return menuItems;
    };

    return (
        <aside className="menu platform-menu">
            <ul className="menu-list">
                {getMenuItems().map((item, i) => (
                    <MenuItem
                        key={i}
                        idx={i + 1}
                        item={item}
                        openItem={openItem}
                        curPageSlug={curPageSlug}
                        onItemChange={handleOpenItemChange}
                        onItemClick={onItemClick}
                        locale={locale}
                    />

                ))}
                <li className="top-level-item is-black">
                    <MenuTopLevelLink
                        href="https://partners.frends.com/?__hstc=181257784.9a1b81dd347d67242620901b4c81137d.1652791382907.1670913716305.1670916387319.167&__hssc=181257784.65.1670916387319&__hsfp=2911786793"
                        target="_blank"
                        isOpen={false}
                        idx={getMenuItems().length + 1}
                        title="Partner Portal"
                    />
                </li>
            </ul>
        </aside>
    )
}

const MenuItem = ({
    idx,
    item,
    openItem,
    curPageSlug,
    onItemChange,
    onItemClick,
    locale
}) => {
    const {
        title,
        slug: itemSlug,
        contentSections
    } = item.node;

    const isOpen = useMemo(() => {
        return openItem === itemSlug
    }, [openItem, itemSlug]);

    const handleOpenMenuButtonClick = () => onItemChange(itemSlug);

    const handleSubItemLinkClick = (e, elId) => {
        if (itemSlug !== curPageSlug) {
            return;
        }

        e.preventDefault();

        const elToScrollTo = `#${elId}`;
        const el = document.querySelector(elToScrollTo);
        if (el) {
            const headerOffset = 90;
            const elementPosition = el.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

            const hrefParts = window.location.href.split('#');
            window.history.pushState({}, null, `${hrefParts[0]}${elToScrollTo}`);
        }

        if (onItemClick) onItemClick();
    };

    return (
        <li className="top-level-item is-black">
            {contentSections.length > 1 ? (
                <>
                    <TopLevelMenuButton
                        isOpen={isOpen}
                        onClick={handleOpenMenuButtonClick}
                        idx={idx}
                        title={title}
                    />

                    {isOpen && (
                        <ul>
                            {contentSections.filter((subItem) => subItem.title).map((subItem, i) => (
                                <li key={i}>
                                    <MenuSubLevelLink
                                        href={`${locale === 'de' ? '/de' : ''}/partner/${itemSlug}/#${slug(subItem.title)}`}
                                        onClick={(e) => handleSubItemLinkClick(e, slug(subItem.title))}
                                        title={subItem.title}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </>
            ) : (
                <MenuTopLevelLink
                    href={`${locale === 'de' ? '/de' : ''}/partner/${itemSlug}#${slug(title)}`}
                    target="_self"
                    isOpen={isOpen}
                    idx={idx}
                    title={title}
                />
            )}
        </li>
    );
}

const TopLevelMenuButton = ({ isOpen, onClick, idx, title, }) => (
    <button
        className={`top-level-title-container ${isOpen ? 'is-open' : ''}`}
        onClick={(e) => onClick(e)}
    >
        <span>{`0${idx}`}</span>
        <span className="top-level-title">{title}</span>
        <svg
            width="26"
            height="36"
            viewBox="0 0 26 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6 15L13 22L20 15" stroke="#58696C" strokeWidth="2" />
        </svg>
    </button>
)

const MenuTopLevelLink = ({ href, target, isOpen, idx, title }) => (
    <a
        href={href}
        target={target}
        className={`top-level-title-container ${isOpen ? 'is-open' : ''}`}
    >
        <span>{`0${idx}`}</span>
        <span className="top-level-title">{title}</span>
    </a>
);

const MenuSubLevelLink = ({ href, onClick, title }) => (
    <a
        href={href}
        onClick={(e) => onClick(e)}
        className="article-item"
    >
        <span className="article-title">
            {title}
        </span>
    </a>
);

const MobileMenu = ({ curPageSlug, menuItems, locale, }) => {
    const [open, setOpen] = React.useState(false);
    const spring = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? "translateY(0px)" : "translateY(1000px)",
        padding: '10px',
        overflow: 'hidden',
    });

    const handleStateChange = () => setOpen(curState => !curState);

    return (
        <div className="mobile-platform-catalogue-container is-hidden-tablet">
            <animated.div
                style={spring}
                className="platform-menu"
            >
                <Menu
                    curPageSlug={curPageSlug}
                    menuItems={menuItems}
                    onItemClick={handleStateChange}
                    locale={locale}
                />

            </animated.div>
            <nav
                className="navbar mobile-platform-catalogue"
                onClick={handleStateChange}
                role="navigation"
                aria-label="platform catalogue"
            >
                <div className="navbar-item">
                    <img
                        src={small_transcript_orange}
                        style={{ verticalAlign: "middle" }}
                        alt=""
                    />
                </div>
                <div className="navbar-item">
                    <p className="is-orange size-24">
                        partner catalogue
                    </p>
                </div>
                <div className="navbar-item chevron">
                    <img
                        src={orange_chevron}
                        style={{
                            verticalAlign: "middle",
                            transform: open ? "rotate(0deg)" : "rotate(180deg)",
                            transition: "all .5s ease"
                        }}
                        alt=""
                    />
                </div>
            </nav>
        </div>
    );
}

const CoSellPartners = ({
    coSellPartners,
    showCountryFilters,
    showTypeFilters
}) => {
    const getFilters = (partners, prop) => {
        const flattenValues = flatten(partners.map((p) => p[prop])).filter((c) => c !== null);
        return uniq(flattenValues).map((v) => ({ value: v, checked: false }));
    }

    const getCountryFilters = () => {
        return getFilters(coSellPartners, 'countries');
    }

    const getTypeFilters = () => {
        return getFilters(coSellPartners, 'types');
    }

    const [shownCoSellPartners, setShownCoSellPartners] = React.useState(coSellPartners);
    const [countryFilters, setCountryFilters] = React.useState(getCountryFilters());
    const [typeFilters, setTypeFilters] = React.useState(getTypeFilters());

    const handleFiltersChange = (value, setFilters) => (setFilters((prev) => (prev.map((f) => ({ ...f, checked: (value === f.value ? !f.checked : f.checked) })))));
    const handleCountryFiltersChange = (value) => handleFiltersChange(value, setCountryFilters);
    const handleTypeFiltersChange = (value) => handleFiltersChange(value, setTypeFilters);
    const getCheckedFilters = (filters) => filters.filter((f) => f.checked).map((f) => f.value);
    const matchPartnersToFilters = (partners, filteredProp, checkedFilters) => {
        if (checkedFilters.length) {
            return partners.filter((p) => intersection(p[filteredProp], checkedFilters).length > 0);
        }
        return partners;
    }

    React.useEffect(() => {
        let filteredCoSellPartners = matchPartnersToFilters(coSellPartners, 'countries', getCheckedFilters(countryFilters));
        filteredCoSellPartners = matchPartnersToFilters(filteredCoSellPartners, 'types', getCheckedFilters(typeFilters));
        setShownCoSellPartners(filteredCoSellPartners);
    }, [coSellPartners, countryFilters, typeFilters]);

    return (
        <section className="hero co-sell-partners-section">
            {(showCountryFilters || showTypeFilters) && (
                <form
                    className="co-sell-partners-filters is-flex is-flex-direction-column is-align-items-start"
                    aria-controls="co-sell-partners-body"
                >
                    {showCountryFilters && (
                        <fieldset className="radio-buttons is-justify-content-start">
                            {countryFilters.map((cf, i) => (
                                <div key={i} className="radio-button">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={cf.value}
                                            checked={cf.checked}
                                            onChange={(e) => handleCountryFiltersChange(e.currentTarget.value)}
                                        />
                                        <span>{cf.value}</span>
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                    )}

                    {showTypeFilters && (
                        <fieldset className="radio-buttons is-justify-content-start">
                            {typeFilters.map((tf, i) => (
                                <div key={i} className="radio-button">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={tf.value}
                                            checked={tf.checked}
                                            onChange={(e) => handleTypeFiltersChange(e.currentTarget.value)}
                                        />
                                        <span>{tf.value}</span>
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                    )}
                </form>
            )}

            <div id="co-sell-partners-body" className="co-sell-partners-body">
                <div className="container">
                    <div className="columns is-multiline">
                        {shownCoSellPartners !== null && shownCoSellPartners.map((post, i) => (
                            <div className="column is-6" key={i}>
                                <CoSellPartner
                                    item={post}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

const CoSellPartner = ({ item }) => {
    const { name, slogan, slug: partnerSlug } = item;
    return (
        <Tilt className="Tilt" options={{ max: 5, scale: 1.015 }}>
            <div className="Tilt-inner">
                <Link to={partnerSlug !== null ? `/partner/co-sell/${partnerSlug}` : `/partner/co-sell/${slug(name)}`}>
                    <div className="card is-white-bg is-card-shadow">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-content">
                                    <h2 className={`title font-bold size-70 is-white text-stroke-black margin-bottom-40`}>{name}</h2>
                                    <h2 className={`title size-30 is-orange`}>{slogan}</h2>
                                </div>
                            </div>
                            <div className="content">
                                <span className={`is-orange is-orange-border-bottom read-more`}>check them out</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </Tilt>
    )
}

export const query = graphql`
query ($contentful_id: String!, $locale: String) {
    contentfulPartnerPage (
        contentful_id: { eq: $contentful_id }
        node_locale: {eq: $locale}
    ) {
        title
        slug
        heroSection {
            titlePart1
            titlePart2
            description
            coverImage {
                gatsbyImageData(width: 1920)
                file {
                    url
                }
            }
        }
        bottomSection {
            logoCloudTitlePart1
            logoCloudTitlePart2
            logoCloud {
                logo {
                    title
                    file {
                        details {
                            image {
                                height
                                width
                            }
                        }
                        url
                    }
                }
            }
            ctaLink
            ctaSubtitle
            ctaTitle
        }
        contentSections {
            ... on ContentfulPartnerPageCoSellPartnerSection {
              title
              backgroundColor
              coSellPartners {
                slogan
                slug
                name
                countries
                types
              }
              showCountryFilters
              showTypeFilters
            }
            ... on ContentfulPartnerPageSection {
              title
              backgroundColor
              childContentfulPartnerPageSectionContentTextNode {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
    }
    allContentfulArticle(
        filter: {subCategory: {eq: "partnerships"}, node_locale: {eq: $locale}}
        limit: 3
        sort: {fields: date, order: DESC}
      ) {
        edges {
            node {
                createdAt
                title
                subtitle
                articleUrl
                category
                date(formatString: "DD.MM.YYYY")
                liftImage {
                    gatsbyImageData(width: 380)
                    title
                }
            }
        }
    }
    allContentfulPartnerPage(
        sort: {fields: createdAt}
        filter: {node_locale: {eq: $locale}}
      ) {
        edges {
            node {
                title
                slug
                contentSections {
                    ... on ContentfulPartnerPageCoSellPartnerSection {
                        title
                    }
                    ... on ContentfulPartnerPageSection {
                        title
                    }
                }
            }
        }
      }
}
`
